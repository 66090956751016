import React from 'react';
//import "@fontsource/montserrat";
//import "@fontsource/montserrat/700.css"; // Peso 700 (Bold)
//import "@fontsource/montserrat/200.css";
//import "@fontsource/montserrat/500.css"; 
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './src/theme';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {element}
  </ThemeProvider> 
);