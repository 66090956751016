exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-servicos-design-grafico-js": () => import("./../../../src/pages/servicos/design-grafico.js" /* webpackChunkName: "component---src-pages-servicos-design-grafico-js" */),
  "component---src-pages-servicos-ecommerce-js": () => import("./../../../src/pages/servicos/ecommerce.js" /* webpackChunkName: "component---src-pages-servicos-ecommerce-js" */),
  "component---src-pages-servicos-index-js": () => import("./../../../src/pages/servicos/index.js" /* webpackChunkName: "component---src-pages-servicos-index-js" */),
  "component---src-pages-servicos-marketing-digital-js": () => import("./../../../src/pages/servicos/marketing-digital.js" /* webpackChunkName: "component---src-pages-servicos-marketing-digital-js" */),
  "component---src-pages-servicos-producao-video-js": () => import("./../../../src/pages/servicos/producao-video.js" /* webpackChunkName: "component---src-pages-servicos-producao-video-js" */),
  "component---src-pages-servicos-servico-seo-js": () => import("./../../../src/pages/servicos/servico-seo.js" /* webpackChunkName: "component---src-pages-servicos-servico-seo-js" */),
  "component---src-pages-servicos-sistema-personalizado-js": () => import("./../../../src/pages/servicos/sistema-personalizado.js" /* webpackChunkName: "component---src-pages-servicos-sistema-personalizado-js" */),
  "component---src-pages-servicos-website-personalizado-js": () => import("./../../../src/pages/servicos/website-personalizado.js" /* webpackChunkName: "component---src-pages-servicos-website-personalizado-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

