module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NineLab","short_name":"9Lab","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"static/images/favicon.svg","icons":[{"src":"static/images/social-preview.png","sizes":"192x192","type":"image/png"},{"src":"static/images/social-preview.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"919e5b8f8686909b6a17191fa4f32e9c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
