import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
          main: "#1fa84f",  // verde principal
          light: "#63a4ff", // Azul mais claro
          dark: "#004ba0",  // Azul mais escuro
          contrastText: "#ffffff", // Cor do texto sobre o fundo primário
        },
        secondary: {
          main: "#6e6d6d", // GRAY
          light: "#ff79b0",
          dark: "#c60055",
          contrastText: "#000000",
        },
        background: {
          default: "#f5f5f5", // Cor de fundo padrão
          paper: "#f8f6f4", // Cor de fundo de elementos como cards
        },
      },
      typography: {
        fontFamily: "Arial ,Roboto , sans-serif", //Montserrat, Outfit, 
        h2: {
          fontSize: '1rem',
          fontWeight: 400, 
          color: 'inherit',
          textTransform: 'uppercase',
        },
        h3:{
          fontSize: '2.2rem',
          fontWeight: 200 
        },
        h6:{
          fontSize: '1.1rem',
          fontWeight: 700 
        },
        p:{
          lineHeight: "1.7",
          textAlign: 'justify', // Alinhamento justificado
          
        }
      },
      components: {
        MuiListItemText: {
          styleOverrides: {
            primary: {
              fontSize: 12
            },
          },
        }
      },
});

export default theme;